import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Modal, Alert, Spinner, Form } from "react-bootstrap";
import { Contract, parseEther, formatEther } from "ethers";
import { Pie, Line } from "react-chartjs-2";
import { Chart, LinearScale, CategoryScale, LineController, LineElement, PointElement, Tooltip, Legend } from "chart.js";
import dynamicPriceShareABI from "../abis/WSHAREGovernanceABI.json";
import { useAppKitAccount } from "@reown/appkit/react";

const blockExplorerUrl = "https://polygonscan.com/tx/";
const CONTRACT_ADDRESS = "0x0dfaB0448dF1dfe6d075b0335121408D550947CA";

const ShareholdingDashboard = ({ signer }) => {
  // Get connection status
  const { isConnected } = useAppKitAccount();

  // Global & user stats
  const [globalStats, setGlobalStats] = useState({
    currentPrice: "",
    lastPurchasePrice: "",
    totalVolumePurchased: "",
    totalHolders: ""
  });
  const [userStats, setUserStats] = useState({
    userShares: "",
    userSalesRewards: "",
    userSystemRewards: ""
  });

  Chart.register(LinearScale, CategoryScale, LineController, LineElement, PointElement, Tooltip, Legend);

  // Modal states for purchase and claim rewards
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [showClaimModal, setShowClaimModal] = useState(false);

  // Loader state for on‑chain data and chain network check
  const [dataLoading, setDataLoading] = useState(true);
  const [chainOk, setChainOk] = useState(false);

  // Purchase input & disclaimer states
  const [maticToSpend, setMaticToSpend] = useState("");
  const [walletBalance, setWalletBalance] = useState("");
  const [estimatedShares, setEstimatedShares] = useState("");
  const [tcChecked, setTcChecked] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [purchaseTxHash, setPurchaseTxHash] = useState(null);

  // Create contract instance if signer is provided
  const contract = signer
    ? new Contract(CONTRACT_ADDRESS, dynamicPriceShareABI, signer)
    : null;

  // Historical data for charts
  const [historicalData, setHistoricalData] = useState([]);

  // 1. Check network status using signer
  useEffect(() => {
    async function checkChain() {
      if (!signer || !window.ethereum) {
        setChainOk(false);
        return;
      }
      try {
        const chainId = await window.ethereum.request({ method: "eth_chainId" });
        setChainOk(chainId.toLowerCase() === "0x89"); // 0x89 === 137 in hex (Polygon Mainnet)
      } catch (err) {
        console.error("Chain check error:", err);
        setChainOk(false);
      }
    }
    checkChain();
  }, [signer]);

  // 2. Load on‑chain data: stats and price history events
  useEffect(() => {
    async function loadData() {
      if (contract) {
        await fetchGlobalStats();
        await fetchUserStats();
        await loadPriceEvents();
        if (signer) {
          const addr = await signer.getAddress();
          const newBal = await signer.provider.getBalance(addr);
          setWalletBalance(formatEther(newBal));
        }
      }
      setDataLoading(false);
    }
    loadData();
  }, [contract, signer]);

  async function loadPriceEvents() {
    if (!contract) return;
    try {
      const filter = contract.filters.PriceHistory();
      const startBlock = await contract.startBlock();
      const events = await contract.queryFilter(filter, startBlock, "latest");
      const tempData = events.map((e) => {
        const { timestamp, price, volume } = e.args;
        return {
          timestamp: Number(timestamp),
          price: parseFloat(formatEther(price)),
          volume: parseFloat(formatEther(volume))
        };
      });
      tempData.sort((a, b) => a.timestamp - b.timestamp);
      setHistoricalData(tempData);
    } catch (err) {
      console.error("Failed to load PriceHistory events:", err);
    }
  }

  async function fetchGlobalStats() {
    if (!contract) return;
    try {
      // getGlobalStats returns [currentPrice, lastPurchasePrice, totalVolumePurchased, totalHolders]
      const [curPrice, lastPrice, totalVol, holders] = await contract.getGlobalStats();
      setGlobalStats({
        currentPrice: formatEther(curPrice),
        lastPurchasePrice: formatEther(lastPrice),
        totalVolumePurchased: formatEther(totalVol),
        totalHolders: holders.toString()
      });
    } catch (err) {
      console.error("Failed to fetch global stats:", err);
    }
  }

  async function fetchUserStats() {
    if (!contract || !signer) return;
    try {
      const addr = await signer.getAddress();
      const [shares, sales, sys] = await contract.getUserStats(addr);
      setUserStats({
        userShares: formatEther(shares),
        userSalesRewards: formatEther(sales),
        userSystemRewards: formatEther(sys)
      });
    } catch (err) {
      console.error("Failed to fetch user stats:", err);
    }
  }

  // 3. Handle POL input change using on‑chain current price
  async function handleMaticChange(value) {
    setMaticToSpend(value);
    if (!contract) return;
    try {
      const typedMatic = parseFloat(value);
      let currentPrice = globalStats.currentPrice
        ? parseFloat(globalStats.currentPrice)
        : parseFloat(formatEther(await contract.getCurrentPrice()));
      if (typedMatic > 0 && currentPrice > 0) {
        const sharesApprox = typedMatic / currentPrice;
        setEstimatedShares(sharesApprox.toFixed(4));
      } else {
        setEstimatedShares("");
      }
    } catch (err) {
      console.error("Error fetching price:", err);
    }
  }

  // 4. Purchase WSHARE
  async function handlePurchase() {
    if (!maticToSpend || parseFloat(maticToSpend) <= 0) {
      alert("Enter a valid POL amount");
      return;
    }
    if (!tcChecked) {
      alert("Please agree to the Terms & Conditions first.");
      return;
    }
    if (!contract) return;
    const typedMatic = parseFloat(maticToSpend);
    const balFloat = parseFloat(walletBalance || "0");
    if (typedMatic > balFloat) {
      alert("Not enough POL in your wallet.");
      return;
    }
    try {
      setIsBuying(true);
      setPurchaseTxHash(null);
      const tx = await contract.buyShares({ value: parseEther(maticToSpend) });
      await tx.wait();
      setPurchaseTxHash(tx.hash);
      setMaticToSpend("");
      setEstimatedShares("");
      await fetchGlobalStats();
      await fetchUserStats();
      await loadPriceEvents();
      setTcChecked(false);
      if (signer) {
        const addr = await signer.getAddress();
        const newBal = await signer.provider.getBalance(addr);
        setWalletBalance(formatEther(newBal));
      }
    } catch (err) {
      console.error("Purchase failed or canceled:", err);
      alert("Purchase failed or canceled.");
    } finally {
      setIsBuying(false);
    }
  }

  function closePurchaseModal() {
    setShowPurchaseModal(false);
    setMaticToSpend("");
    setEstimatedShares("");
    setPurchaseTxHash(null);
    setTcChecked(false);
  }

  // 5. Claim rewards handler
  async function handleClaimRewards() {
    if (!contract) return;
    try {
      const tx = await contract.claimRewards();
      await tx.wait();
      alert("Rewards claimed!");
      setShowClaimModal(false);
      await fetchUserStats();
    } catch (err) {
      console.error("Claim error:", err);
      alert("Claim failed or canceled.");
    }
  }

  // Prepare line chart data
  const lineChartData = {
    labels: historicalData.map((d) =>
      new Date(d.timestamp * 1000).toLocaleDateString()
    ),
    datasets: [
      {
        label: "WSHARE Price (POL)",
        data: historicalData.map((d) => d.price),
        yAxisID: "yPrice",
        borderColor: "#5261DD",
        backgroundColor: "rgba(82, 97, 221, 1)",
        fill: false,
        tension: 0.2
      },
      {
        label: "Total Volume (POL)",
        data: historicalData.map((d) => d.volume),
        yAxisID: "yVolume",
        borderColor: "#00E898",
        backgroundColor: "rgba(0, 232, 152, 1)",
        fill: false,
        tension: 0.2
      }
    ]
  };

  const lineChartOptions = {
    responsive: true,
    scales: {
      yPrice: {
        type: "linear",
        position: "left",
        title: { display: true, text: "Price (POL)" }
      },
      yVolume: {
        type: "linear",
        position: "right",
        title: { display: true, text: "Volume (POL)" },
        grid: { drawOnChartArea: false }
      }
    }
  };

  const userShareFloat = parseFloat(userStats.userShares) || 0;
  const restShareFloat = Math.max(100 - userShareFloat, 0);

  return (
    <Container className="mt-4">
      {dataLoading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 1050
          }}
        >
          <Spinner animation="border" variant="light" />
          <span className="ms-2 text-white">Loading Dashboard...</span>
        </div>
      )}

      {(!isConnected || !chainOk) && (
        <Alert variant="warning" className="mt-3">
          To manage your shareholding, please connect your wallet on Polygon (chainId 0x89).
        </Alert>
      )}

      <h2 className="text-center my-4">WSHARE Dashboard</h2>
      <p className="text-center text-muted mb-4">
        Manage your shareholding, see current price, claim rewards, and buy more WSHARE.
      </p>

      <Row className="mb-4">
        <Col md={4}>
          <Card className="mb-3">
            <Card.Header className="bg-primary text-white">Global Stats</Card.Header>
            <Card.Body>
              <p>Current Price: {parseFloat(globalStats.currentPrice).toFixed(6)} POL</p>
              <p>Last Purchase Price: {parseFloat(globalStats.lastPurchasePrice).toFixed(6)} POL</p>
              <p>Total Volume Purchased: {parseFloat(globalStats.totalVolumePurchased).toFixed(2)} POL</p>
              <p>Total Holders: {globalStats.totalHolders}</p>
              <Button variant="primary" onClick={() => setShowPurchaseModal(true)} className="mt-3">
                Buy WSHARE
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-3">
            <Card.Header className="bg-success text-white">My Stats</Card.Header>
            <Card.Body>
              <p>My WSHARE: {parseFloat(userStats.userShares).toFixed(2)} WSHARE</p>
              <p>Unclaimed Sales: {parseFloat(userStats.userSalesRewards).toFixed(2)} POL</p>
              <p>Unclaimed Fees: {parseFloat(userStats.userSystemRewards).toFixed(2)} POL</p>
              <Button variant="secondary" onClick={() => setShowClaimModal(true)} className="mt-3 me-2">
                Claim Rewards
              </Button>
              <Button variant="primary" onClick={handleClaimRewards} className="mt-3">
                Reinvest Rewards
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-3">
            <Card.Header className="bg-warning text-dark">Share Distribution</Card.Header>
            <Card.Body className="text-center">
              <div style={{ width: "200px", height: "200px", margin: "0 auto" }}>
                <Pie
                  data={{
                    labels: ["My WSHARE", "Others"],
                    datasets: [
                      {
                        data: [userShareFloat, restShareFloat],
                        backgroundColor: ["#00E898", "#5261DD"],
                        borderColor: ["#00E898", "#5261DD"],
                        borderWidth: 1
                      }
                    ]
                  }}
                />
              </div>
              <p className="mt-3">
                You currently own {parseFloat(userStats.userShares).toFixed(2)} out of 100 total WSHARE.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card className="mb-4">
        <Card.Header className="bg-dark text-white">WSHARE Price & Volume History</Card.Header>
        <Card.Body>
          <div style={{ minWidth: "600px", minHeight: "400px" }}>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        </Card.Body>
      </Card>

      {/* Integrated Purchase Modal */}
      <Modal show={showPurchaseModal && !purchaseTxHash} onHide={closePurchaseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Buy WSHARE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your Wallet Balance: {parseFloat(walletBalance).toFixed(2)} POL</p>
          <Form.Group className="mb-3">
            <Form.Control
              type="number"
              placeholder="POL to spend"
              value={maticToSpend}
              onChange={(e) => handleMaticChange(e.target.value)}
            />
          </Form.Group>
          {estimatedShares && (
            <>
              <p className="mb-1">Estimated WSHARE: {estimatedShares}</p>
              <p className="mb-2">
                After purchase, total WSHARE ~{" "}
                {(
                  parseFloat(userStats.userShares) +
                  (parseFloat(estimatedShares) || 0)
                ).toFixed(4)}{" "}
                (
                {(
                  ((parseFloat(userStats.userShares) +
                    (parseFloat(estimatedShares) || 0)) /
                    100) *
                  100
                ).toFixed(2)}
                %)
              </p>
            </>
          )}
          <p className="mb-4">
            Final price may change if others buy before your transaction is confirmed.
          </p>
          <Form.Group className="mb-3" controlId="buyTerms">
            <Form.Check
              type="checkbox"
              label="I agree to the Terms & Conditions"
              checked={tcChecked}
              onChange={(e) => setTcChecked(e.target.checked)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handlePurchase}
            disabled={
              !tcChecked ||
              parseFloat(maticToSpend || "0") > parseFloat(walletBalance || "0")
            }
          >
            {isBuying ? "Processing..." : "Confirm Purchase"}
          </Button>
          <Button variant="secondary" onClick={closePurchaseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Claim Rewards Modal */}
      <Modal show={showClaimModal} onHide={() => setShowClaimModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Claim Rewards</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Sales Rewards: {userStats.userSalesRewards} POL</p>
          <p>System Fees: {userStats.userSystemRewards} POL</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClaimRewards}>
            Claim Now
          </Button>
          <Button variant="secondary" onClick={() => setShowClaimModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ShareholdingDashboard;
