import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Alert,
  Spinner,
  Button,
} from "react-bootstrap";
import Logo from "./logo.svg";
import ConnectButton from "./components/ConnectButton";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
//import Dashboard from "./components/Dashboard";
//import Nodes from "./components/Nodes";
import Staking from "./components/Staking";
import StakingGlobal from "./components/StakingGlobal";
//import Affiliate from "./components/Affiliate";
import Info from "./components/Info";
//import NodeInfo from "./components/NodeInfo";
//import NodeMergeSection from "./components/NodeMergeSection";
import FAQs from "./components/FAQs";
import Footer from "./components/Footer";
import FlipNotification from "./components/FlipNotification";
import TokenSale from "./components/TokenSale";
//import NodesList from "./components/NodesList";
import Bridge from "./components/Bridge";
import MarketCap from "./components/MarketCap";
import SwapV3 from "./components/SwapV3";
import SwapV1 from "./components/SwapV1";
import Tokens from "./components/Tokens";
import Management from "./components/Management";
import HeroSection from "./components/HeroSection";
import CardSection from "./components/CardSection";
import CardMarqueeSection from "./components/CardMarqueeSection";
import RoadmapSection from "./components/RoadmapSection";
import SiteMap from "./components/SiteMap";
import Supplies from "./components/Supplies";
import { MdSwapCalls } from "react-icons/md";
import StandardVoting from "./components/dao/StandardVoting";
import WeightedVoting from "./components/dao/WeightedVoting";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  parseUnits,
  toQuantity,
} from "ethers";
import SwapV1BNB from "./components/SwapV1BNB";
import Modal from "./components/Modal";
import ModalTest from "./components/ModalTest";
import SETTINGS from "./SETTINGS";
import FlipTest from "./components/FlipTest";
import AdminContainer from "./components/admin/AdminContainer";
import ProfitCentre from "./components/ProfitCentre";
import Shareholders from "./components/Shareholders";
import WSHAREPurchaseModal from "./components/WSHAREPurchaseModal";
import ShareholdingDashboard from "./components/ShareholdingDashboard";
import { MdOutlineHowToVote, MdHowToVote } from "react-icons/md";
import { TbCoins } from "react-icons/tb";
import { BsRepeat, BsShuffle, BsArrowRepeat } from "react-icons/bs";
import { BiSolidPieChartAlt2, BiSolidBank, BiSolidCoinStack, BiCoinStack, BiLineChart, BiSupport  } from "react-icons/bi";


function App() {
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [expanded, setExpanded] = useState(false);
  const [networkName, setNetworkName] = useState("");
  const [networkId, setNetworkId] = useState("");
  const [nativeCurrencySymbol, setNativeCurrencySymbol] = useState("");
  const [showModal, setShowModal] = useState(false);
  
  // Create an ethers signer once we have both walletProvider and address
  const [signer, setSigner] = useState(null);
  useEffect(() => {
    async function setupSigner() {
      if (walletProvider && address) {
        try {
          const provider = new BrowserProvider(walletProvider);
          // Using getSigner(address) ensures the correct account is selected
          const newSigner = await provider.getSigner(address);
          setSigner(newSigner);
        } catch (err) {
          console.error("Error creating signer:", err);
        }
      } else {
        setSigner(null);
      }
    }
    setupSigner();
  }, [walletProvider, address]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLinkClick = () => {
    setExpanded(false);
  };

  async function switchNetwork(chainId) {
    if (walletProvider) {
      walletProvider?.removeListener("chainChanged", handleNetworkChange);
      const ethersProvider = new BrowserProvider(walletProvider);
      await ethersProvider.send("wallet_switchEthereumChain", [
        { chainId: toQuantity(chainId) },
      ]);
      const network = await ethersProvider.getNetwork();
      setNetworkName(network.name);
      setNetworkId(chainId);
      setNativeCurrencySymbol(network.name);
    }
  }

  const fetchNetworkInfo = async () => {
    if (walletProvider) {
      const ethersProvider = new BrowserProvider(walletProvider);
      const network = await ethersProvider.getNetwork();
      setNetworkName(network.name);
      setNetworkId(network.chainId.toString());
      setNativeCurrencySymbol(network.name);
    }
  };

  const handleNetworkChange = async (chainId) => {
    console.log(`Network switched to: ${chainId}`);
    setNetworkId(chainId);
    await fetchNetworkInfo();
  };

  useEffect(() => {
    fetchNetworkInfo();
    walletProvider?.on("chainChanged", handleNetworkChange);
    return () => {
      walletProvider?.removeListener("chainChanged", handleNetworkChange);
    };
  }, [walletProvider]);

  return (
    <Router>
      <div className="relative min-h-screen">
        <div className="flip-notification-wrapper">
          {SETTINGS.IS_TEST ? <FlipTest /> : <FlipNotification />}
        </div>
        <Navbar expand="lg" className="top-bar" expanded={expanded}>
          <Container style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <Navbar.Brand as={Link} to="https://waveswaps.com/" onClick={handleLinkClick}>
              <img src={Logo} style={{ width: "100px" }} alt={"Logo"} />
            </Navbar.Brand>
            <div className="d-block d-lg-none">
              <ConnectButton />
            </div>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(expanded ? false : "expanded")}
              className="px-0"
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto mt-lg-0 mt-4 navbar-bkg">
                <Nav.Link as={Link} to="/" className="menuBtn" onClick={handleLinkClick}>
                  Home
                </Nav.Link>
                {!SETTINGS.IS_TEST && (
                <NavDropdown
                  title={<span> Swaps</span>}
                  id="basic-nav-dropdown"
                  className="menuBtn dropdown-padding"
                >
                  <NavDropdown.Item>
                    <Nav.Link as={Link} to="/bridge" onClick={handleLinkClick}>
                      <span>
                        <BsShuffle />
                        CrosChain Swap
                      </span>
                    </Nav.Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Nav.Link as={Link} to="/swap-v3" onClick={handleLinkClick}>
                      <span>
                        <BsRepeat />
                        V3 GBL Bot
                      </span>
                    </Nav.Link>
                  </NavDropdown.Item>
                  {parseInt(networkId) == 137 && (
                    <NavDropdown.Item>
                      <Nav.Link
                        as={Link}
                        to="/swap-v1"
                        onClick={handleLinkClick}
                      >
                        <span>
                          <BsArrowRepeat />
                          V1 GBL Bot
                        </span>
                      </Nav.Link>
                    </NavDropdown.Item>
                  )}
                  {parseInt(networkId) == 56 && (
                    <NavDropdown.Item>
                      <Nav.Link
                        as={Link}
                        to="/swap-v1-bnb"
                        onClick={handleLinkClick}
                      >
                        <span>
                          <BsArrowRepeat />
                          V1 GBL Bot
                        </span>
                      </Nav.Link>
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              )}

              <NavDropdown
                title={<span> Stakes</span>}
                id="basic-nav-dropdown"
                className="menuBtn dropdown-padding"
              >
                <NavDropdown.Item>
                  <Nav.Link as={Link} to="/staking" onClick={handleLinkClick}>
                    <span>
                      <BiCoinStack /> Stake WAVE
                    </span>
                  </Nav.Link>
                </NavDropdown.Item>
                {!SETTINGS.IS_TEST && (
                  <NavDropdown.Item>
                    <Nav.Link
                      as={Link}
                      to="/staking-gbl"
                      onClick={handleLinkClick}
                    >
                      <span>
                        <BiSolidCoinStack /> Stake GBL
                      </span>
                    </Nav.Link>
                  </NavDropdown.Item>
                )}
              </NavDropdown>
              <NavDropdown
                title={<span> DAO</span>}
                id="basic-nav-dropdown"
                className="menuBtn dropdown-padding"
              >
                <NavDropdown.Item>
                  <Nav.Link
                    as={Link}
                    to="/dao-standard"
                    onClick={handleLinkClick}
                  >
                    <span>
                      <MdOutlineHowToVote /> Standard Voting
                    </span>
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link
                    as={Link}
                    to="/dao-weighted"
                    onClick={handleLinkClick}
                  >
                    <span>
                      <MdHowToVote /> Weighted Voting
                    </span>
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                    <Nav.Link as={Link} to="/wshare-dpmc" onClick={handleLinkClick}><span><BiSolidPieChartAlt2 />WSHARE Governance</span></Nav.Link>
                </NavDropdown.Item>
                           
                <NavDropdown.Item>
                  <Nav.Link
                    as={Link}
                    to="/profit-centre"
                    onClick={handleLinkClick}
                  >
                    <span>
                      <BiSolidBank /> Profit Centres
                    </span>
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title={<span>Info</span>}
                id="basic-nav-dropdown"
                className="menuBtn dropdown-padding"
              >
                <NavDropdown.Item>
                  <Nav.Link as={Link} to="/tokens" onClick={handleLinkClick}>
                    <span>
                      <BiLineChart /> Tokens
                    </span>
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link as={Link} to="/supplies" onClick={handleLinkClick}>
                    <span>
                      <TbCoins /> Multichain Supplies
                    </span>
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link as={Link} to="/FAQs" onClick={handleLinkClick}>
                    <span>
                      <BiSupport /> FAQs
                    </span>
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>

          <div className="d-none d-lg-block">
            <ConnectButton />
          </div>
        </Container>
      </Navbar>

        <div className="pb-5">
          {/* Optional modal display logic */}
          {showModal && !SETTINGS.IS_TEST && <Modal onClose={handleCloseModal} />}
          {showModal && SETTINGS.IS_TEST && <ModalTest onClose={handleCloseModal} />}
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <HeroSection />
                  <CardMarqueeSection />
                  <Container className="pb-5">
                    {!SETTINGS.IS_TEST && (
                      <Bridge
                        provider={walletProvider}
                        account={address}
                        isConnected={isConnected}
                        networkId={networkId}
                        switchNetwork={switchNetwork}
                        networkName={networkName}
                        isFirstSite={true}
                      />
                    )}
                   {/* <Tokens />*/}
                    <RoadmapSection />
                    <FAQs />
                  </Container>
                </>
              }
            />

          <Route
            path="/admin/*"
            element={
              <AdminContainer
                provider={walletProvider}
                networkSymbol={nativeCurrencySymbol}
                selectedAccount={address}
                isConnected={isConnected}
                networkId={networkId}
                networkName={networkName}
                switchNetwork={switchNetwork}
              />
            }
          />
          <Route
            path="/FAQs"
            element={
              <Container className="pb-5">
                <FAQs />
              </Container>
            }
          />
          <Route
            path="/supplies"
            element={
              <Container className="pb-5">
                <Supplies />
              </Container>
            }
          />
          <Route
            path="/liquidity-providers"
            element={
              <Container className="pb-5">
                <Shareholders />
              </Container>
            }
          />
          <Route
            path="/profit-centre"
            element={
              <Container className="pb-5">
                <ProfitCentre />
              </Container>
            }
          />

          <Route
            path="/swap-v3"
            element={
              <Container className="pb-5">
                <SwapV3
                  provider={walletProvider}
                  networkSymbol={nativeCurrencySymbol}
                  selectedAccount={address}
                  isConnected={isConnected}
                  networkId={networkId}
                  networkName={networkName}
                  switchNetwork={switchNetwork}
                />
              </Container>
            }
          />
          <Route
            path="/swap-v1"
            element={
              <Container className="pb-5">
                <SwapV1
                  provider={walletProvider}
                  networkSymbol={nativeCurrencySymbol}
                  selectedAccount={address}
                  isConnected={isConnected}
                  networkId={networkId}
                  networkName={networkName}
                  switchNetwork={switchNetwork}
                />
              </Container>
            }
          />
          <Route
            path="/swap-v1-bnb"
            element={
              <Container className="pb-5">
                <SwapV1BNB
                  provider={walletProvider}
                  networkSymbol={nativeCurrencySymbol}
                  selectedAccount={address}
                  isConnected={isConnected}
                  networkId={networkId}
                  networkName={networkName}
                  switchNetwork={switchNetwork}
                />
              </Container>
            }
          />
          <Route
            path="/info"
            element={
              <Container className="pb-5">
                <Info provider={walletProvider} />
              </Container>
            }
          />
         {/**  <Route
            path="/node-info"
            element={
              <Container className="pb-5">
                <NodeInfo provider={walletProvider} />
              </Container>
            }
          />*/}
          <Route
            path="/staking"
            element={
              <Container className="pb-5">
                <Staking
                  provider={walletProvider}
                  account={address}
                  networkId={networkId}
                  switchNetwork={switchNetwork}
                  isConnected={isConnected}
                />
              </Container>
            }
          />
          <Route
            path="/staking-gbl"
            element={
              <Container className="pb-5">
                <StakingGlobal
                  provider={walletProvider}
                  account={address}
                  networkId={networkId}
                  switchNetwork={switchNetwork}
                  isConnected={isConnected}
                />
              </Container>
            }
          />
          {/** <Route
            path="/affiliate"
            element={
              <Container className="pb-5">
                <Affiliate
                  provider={walletProvider}
                  account={address}
                  isConnected={isConnected}
                />
              </Container>
            }
          />*/}
          <Route
            path="/management"
            element={
              <Container className="pb-5">
                <Management
                  provider={walletProvider}
                  account={address}
                  isConnected={isConnected}
                />
              </Container>
            }
          />

          <Route
            path="/token"
            element={
              <Container className="pb-5">
                <TokenSale
                  provider={walletProvider}
                  account={address}
                  isConnected={isConnected}
                />
              </Container>
            }
          />
          <Route
            path="/tokens"
            element={
              <Container className="pb-5">
                <Tokens
                  provider={walletProvider}
                  account={address}
                  isConnected={isConnected}
                />
              </Container>
            }
          />
          <Route
            path="/site-map"
            element={
              <Container className="pb-5">
                <SiteMap
                  provider={walletProvider}
                  account={address}
                  isConnected={isConnected}
                />
              </Container>
            }
          />
          {/**    <Route path="/dashboard" element={<Container className='pb-5'><Dashboard provider={walletProvider} address={address} isConnected={isConnected} networkId={networkId} switchNetwork={switchNetwork} /></Container>} />
      <Route path="/store" element={<Container className='pb-5'><Nodes networkId={networkId} switchNetwork={switchNetwork} provider={walletProvider} account={address} isConnected={isConnected} /></Container>} />
          <Route path="/craft" element={<Container className='pb-5'><NodeMergeSection networkId={networkId} switchNetwork={switchNetwork} provider={walletProvider} account={address} isConnected={isConnected} /></Container>} />
          <Route path="/list" element={<Container className='pb-5'><NodesList provider={walletProvider} address={address} networkId={networkId} switchNetwork={switchNetwork} isConnected={isConnected} /></Container>} />*/}
          <Route
            path="/roadmap"
            element={
              <Container className="pb-5">
                <div style={{ marginBottom: "250px" }}>
                  <RoadmapSection
                    provider={walletProvider}
                    account={address}
                    isConnected={isConnected}
                    networkId={networkId}
                    networkName={networkName}
                  />
                </div>
              </Container>
            }
          />
          <Route
            path="/liquidity-engines"
            element={
              <Container className="pb-5">
                <div style={{ marginBottom: "250px" }}>
                  <CardMarqueeSection
                    provider={walletProvider}
                    account={address}
                    isConnected={isConnected}
                    networkId={networkId}
                    networkName={networkName}
                  />
                </div>
              </Container>
            }
          />
          <Route
            path="/bridge"
            element={
              <Container className="pb-5">
                <div style={{ marginBottom: "300px" }}>
                  <Bridge
                    isFirstSite={false}
                    provider={walletProvider}
                    account={address}
                    isConnected={isConnected}
                    networkId={networkId}
                    switchNetwork={switchNetwork}
                    networkName={networkName}
                  />
                </div>
              </Container>
            }
          />
          <Route
            path="/dao-standard"
            element={
              <Container className="pb-5">
                <StandardVoting
                  provider={walletProvider}
                  address={address}
                  isConnected={isConnected}
                />
              </Container>
            }
          />
          <Route
            path="/dao-weighted"
            element={
              <Container className="pb-5">
                <WeightedVoting
                  provider={walletProvider}
                  address={address}
                  isConnected={isConnected}
                />
              </Container>
            }
          />
            <Route path="/wshare-dpmc" element={<ShareholdingDashboard signer={signer} />} />
            <Route path="/buy-wshare" element={<WSHAREPurchaseModal isOpen={true} onClose={() => {}} />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
