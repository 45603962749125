import React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SETTINGS from '../SETTINGS';



const FAQs = () => {
  const navigate = useNavigate();
  const openInfo = () => {
    navigate('/info');
  }
  return (

    <Container className="px-0 mt-0">
      <section className="faq faqtable">
        <h2 className="pageTitle faq-header">Frequently Asked Questions</h2>
        <p className="hero-p sub-header">
          Find answers to common questions and explore helpful insights in our FAQ section.
        </p>

        <Accordion flush>
          <Accordion.Item eventKey="0" className="mb-2">
            <Accordion.Header>What is WaveSwaps?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
                WaveSwaps is a decentralized platform that addresses token liquidity by allowing users to recycle hard-to-trade tokens into liquid assets. Using features like GBL bots and Liquidity Nodes, WaveSwaps helps users unlock the value of their assets while supporting a stable, sustainable market.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className="mb-2">
            <Accordion.Header>How does WaveSwaps improve token liquidity?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
                WaveSwaps tackles token illiquidity by recycling tokens into liquid assets, such as GBL tokens and WaveCoin. This process reduces oversupply and allows for a balanced market, ensuring that tokens remain valuable and tradeable.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2" className="mb-2">
            <Accordion.Header>What is WaveCoin?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
                WaveCoin ({SETTINGS.tokenSymbol}) is the native token of the WaveSwaps platform, built on the Polygon network. It is released through recycling mechanisms, with an initial supply of 1 million tokens dedicated to liquidity on decentralized exchanges.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3" className="mb-2">
            <Accordion.Header>How can I acquire {SETTINGS.tokenSymbol} tokens?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
                You can acquire {SETTINGS.tokenSymbol} tokens by purchasing them on a DEX, earning them through platform functionalities or by claiming through merged V1 and V3 GBL Bots.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4" className="mb-2">
            <Accordion.Header>What is V1 GBL bot?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
                Developed from the Swap V1, this innovative mechanism allows users to leverage GBL from the Polygon Mainnet as liquidity when revitalizing tokens from decentralized exchanges (DEX) and allocating them to the Recycle pool. This process not only improves GBL liquidity but also reduces the supply of inactive tokens, increasing their value.<br /><br />New Option: Users can now claim remaining balances through WaveCoin. However, this is an additional, optional feature. If not chosen, swaps will continue processing as set innitially.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5" className="mb-2">
            <Accordion.Header>What is V3 GBL bot?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
              Developed from the Swap V3, it offers users the opportunity to increase their deposited liquidity provision value by 10% to 50%, potentially providing stable returns in the form of a stable coin. Additionally, purchasing GBL coin increases liquidity, contributing to market stability and reducing volatility.<br /><br />New Option: Users can now claim remaining balances through WaveCoin. However, this is an additional, optional feature. If not chosen, swaps will continue processing as set innitially.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          {/*<Accordion.Item eventKey="6" className="mb-2">
            <Accordion.Header>What are Liquidity Nodes and PowerCores?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
              Liquidity Nodes and PowerCores are specialized NFTs within WaveSwaps that empower users to recycle tokens and earn daily rewards. PowerCores energize Liquidity Nodes, which in turn generate WaveCoin rewards, contributing to the platform's liquidity. When a Liquidity Node's cycle ends, it can be renewed indefinitely, allowing continuous earning potential.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7" className="mb-2">
            <Accordion.Header>What is the Craft Book?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
                The Craft Book is a guide that lists requirements for crafting assets like Liquidity Nodes and PowerCores. Users follow the Craft Book to upgrade their assets, maximizing their rewards and platform benefits.
              </p>
            </Accordion.Body>
          </Accordion.Item> */}

          <Accordion.Item eventKey="8" className="mb-2">
            <Accordion.Header>How does staking work on WaveSwaps?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
                Staking lets users earn rewards by locking {SETTINGS.tokenSymbol} of GBL tokens for a set period. The “Max” button in the staking interface allows users to stake their full balance, and rewards accumulate over time.<br /><br />Staking rewards depend on the Annual Percentage Yield (APY), the amount staked, and the chosen staking duration. Rewards can be claimed once the staking period ends.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="9" className="mb-2">
            <Accordion.Header>What is WSHARE Governance on WaveSwaps?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
                WaveSwaps is community-driven through its shareholding model. Users with WSHARE tokens can vote on important decisions, ensuring that the platform’s development is guided by its community.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          {/* <Accordion.Item eventKey="10" className="mb-2">
            <Accordion.Header>How does the referral system work?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
                The referral system rewards users with {SETTINGS.tokenSymbol} tokens for inviting new users. Referrals also generate PowerCores, which can be used to enhance platform rewards.
              </p>
            </Accordion.Body>
          </Accordion.Item> */}

          <Accordion.Item eventKey="11" className="mb-2">
            <Accordion.Header>What is the DAO Voting Model on WaveSwaps?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
              WaveSwaps utilizes a decentralized voting model that includes both Standard and Weighted proposals. Standard proposals offer equal voting power to all participants, with each vote counting equally. In contrast, Weighted proposals allow users to increase their voting power by locking more tokens, giving added influence to participants who contribute more to the DAO. This dual approach ensures that all voices are heard while incentivizing deeper participation in governance
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="12" className="mb-2">
            <Accordion.Header>What makes WaveSwaps sustainable?</Accordion.Header>
            <Accordion.Body>
              <p className="justify">
                WaveSwaps follows a sustainable model by recycling tokens into liquid assets, reducing oversupply and boosting token value. This recycling aligns with global sustainability trends, creating a balanced and responsible DeFi ecosystem.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    </Container>

    
  );
};

export default FAQs;