import React from 'react';
import { useNavigate } from 'react-router-dom';

const nodesImg = '/assets/images/nodes.svg';
const nodesImgT = '/assets/images/testnodes.svg';
const botsImgv1 = '/assets/images/V1gbl.svg';
const botsImgv3 = '/assets/images/V3gbl.svg';
const botsImggStake = '/assets/images/gblstake.svg';
const botsImgwStake = '/assets/images/wavestake.svg';


const CardMarqueeSection = () => {
  const navigate = useNavigate();

  const openLink = (_link) => {
    navigate(_link);
  }
  return (
    <section className="marquee-section cardtable">
      <h2 className="cards-header pageTitle">Liquidity Engines</h2> 
      <p className="hero-p sub-header">Designed to unlock value, boost token liquidity, and empower seamless asset recycling across networks.</p> 
      <div className="marquee"
        onMouseEnter={(e) => e.currentTarget.style.animationPlayState = 'paused'}
        onMouseLeave={(e) => e.currentTarget.style.animationPlayState = 'running'}
      >
        {[...Array(2)].map((_, index) => (
          <div key={index} className="marquee-content">
            {/* Card components */}
            <div className="wcard wcard-one">
              <img src={nodesImg} alt="Wave Nodes" className="wcard-image" />
              <p className="text-center">NFT-based assets, daily<br />generating  liquidity rewards</p>
              <button
                className="btn wcard-button"
                onClick={() => openLink('/dashboard')}
              >
                Enter Liquidity Nodes
              </button>
            </div>

            <div className="wcard wcard-three">
              <img src={nodesImgT} alt="Wave Nodes" className="wcard-image" />
              <p className="text-center">Experience features in a<br />secure test network</p>
              <button
                className="btn wcard-three-button"
                onClick={() => window.location.href='https://test.waveswaps.com/' } target="_blank" rel="noopener noreferrer"
              >
                Experience Testing
              </button>
            </div>

            <div className="wcard wcard-v2">
              <img src={botsImgv1} alt="GBL Bots" className="wcard-image-v1" />
              <p className="text-center">Efficient GBL token<br />recycling on Smartchain</p>
              <button
                className="btn wcard-v1-button"
                onClick={() => openLink('/swap-v1')}
              >
                Start Bot
              </button>
            </div>

            <div className="wcard wcard-v3">
              <img src={botsImgv3} alt="GBL Bots" className="wcard-image-v3" />
              <p className="text-center">Advanced token revamp<br />on Smartchain and Polygon</p>
              <button
                className="btn wcard-v3-button"
                onClick={() => openLink('/swap-v3')}
              >
                Start Bot
              </button>
            </div>

            <div className="wcard wcard-two">
              <img src={botsImggStake} alt="GBL Bots" className="wcard-image" />
              <p className="text-center">Flexible GBL staking<br />to grow and earn rewards</p>
              <button
                className="btn wcard-button"
                onClick={() => openLink('/staking-gbl')}
              >
                Open Stake
              </button>
            </div>
            <div className="wcard wcard-two">
              <img src={botsImgwStake} alt="GBL Bots" className="wcard-image" />
              <p className="text-center">Flexible WAVE staking<br />to grow and earn rewards</p>
              <button
                className="btn wcard-button"
                onClick={() => openLink('/staking')}
              >
                Open Stake
              </button>
            </div>


            {/*<div className="wcard wcard-three">
              <img src={nodesImgT} alt="Wave Nodes" className="wcard-image" />
              <p className="text-center">Experience features in a<br />secure test network</p>
              <button
                className="btn wcard-three-button"
                onClick={() => window.location.href='https://test.waveswaps.com/' } target="_blank" rel="noopener noreferrer"
              >
                Experience Testing
              </button>
            </div>*/}

            <div className="wcard wcard-v2">
              <img src={botsImgv1} alt="GBL Bots" className="wcard-image-v1" />
              <p className="text-center">Efficient GBL token<br />recycling on Smartchain</p>
              <button
                className="btn wcard-v1-button"
                onClick={() => openLink('/swap-v1')}
              >
                Start Bot
              </button>
            </div>

            <div className="wcard wcard-v3">
              <img src={botsImgv3} alt="GBL Bots" className="wcard-image-v3" />
              <p className="text-center">Advanced token revamp<br />on Smartchain and Polygon</p>
              <button
                className="btn wcard-v3-button"
                onClick={() => openLink('/swap-v3')}
              >
                Start Bot
              </button>
            </div>

            <div className="wcard wcard-two">
              <img src={botsImggStake} alt="GBL Bots" className="wcard-image" />
              <p className="text-center">Flexible GBL staking<br />to grow and earn rewards</p>
              <button
                className="btn wcard-button"
                onClick={() => openLink('/staking-gbl')}
              >
                Open Stake
              </button>
            </div>
            <div className="wcard wcard-two">
              <img src={botsImgwStake} alt="GBL Bots" className="wcard-image" />
              <p className="text-center">Flexible WAVE staking<br />to grow and earn rewards</p>
              <button
                className="btn wcard-button"
                onClick={() => openLink('/staking')}
              >
                Open Stake
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CardMarqueeSection;
