import React, { useState } from "react";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import { Contract, parseEther, BrowserProvider } from "ethers";
import { contractAddress, wsharePrice, chainId, tokenInfo } from "../SETTINGS";
import wshareAbi from "../abis/wshareAbi";
import { useAppKitAccount, useAppKitProvider } from "@reown/appkit/react";
import copyIcon from "../assets/copy.svg";
import metamaskIcon from "../assets/metamask.svg";

const WSHAREPurchaseModal = ({ isOpen, onClose }) => {
  const [wshareAmount, setWshareAmount] = useState("");
  const [bnbAmount, setBnbAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [transactionHash, setTransactionHash] = useState(null);

  const { walletProvider } = useAppKitProvider("eip155");
  const account = useAppKitAccount();

  // Use tokenInfo from config
  const token = tokenInfo;

  if (!token || token.stablePrice === undefined) {
    return (
      <Modal show={isOpen} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Buy Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">Loading token information...</p>
        </Modal.Body>
      </Modal>
    );
  }

  // Update required BNB amount based on WSHARE input
  const handleWshareChange = (e) => {
    const amount = e.target.value;
    setWshareAmount(amount);
    const price = parseFloat(token.stablePrice) || 0;
    setBnbAmount((parseFloat(amount) * price).toFixed(6));
  };

  const connectWallet = async () => {
    try {
      if (walletProvider && walletProvider.connect) {
        await walletProvider.connect();
      }
    } catch (error) {
      console.error("Wallet connection error:", error);
    }
  };

  const switchNetwork = async () => {
    if (!walletProvider) return;
    try {
      await walletProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId }],
      });
    } catch (error) {
      console.error("Error switching network:", error);
      throw error;
    }
  };

  const addToMetaMask = async () => {
    if (!window.ethereum) {
      alert("MetaMask not found. Cannot auto-add token.");
      return;
    }
    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: token.contractAddress,
            symbol: token.symbol,
            decimals: token.decimals || 18,
            image: token.logo || "",
          },
        },
      });
      alert(`${token.symbol} was added to MetaMask!`);
    } catch (err) {
      console.error("Error adding token:", err);
      alert("Failed to add token to MetaMask.");
    }
  };

  // Calculate the total cost
  const calculateTotalCost = () => {
    const qty = parseFloat(wshareAmount) || 0;
    const price = parseFloat(token.stablePrice) || 0;
    return qty * price;
  };

  const purchaseWshare = async () => {
    if (!account?.address) {
      alert("Please connect your wallet first!");
      return;
    }
    if (!wshareAmount || parseFloat(wshareAmount) <= 0) {
      alert("Please enter a valid amount of WSHARE to purchase.");
      return;
    }
    try {
      setLoading(true);
      setErrorMessage("");
      setTransactionHash(null);

      if (!walletProvider) {
        throw new Error("Wallet provider is not available.");
      }

      let ethersProvider = new BrowserProvider(walletProvider);
      let signer = await ethersProvider.getSigner();
      if (!signer) {
        throw new Error("Signer is not available.");
      }

      const currentNet = await ethersProvider.getNetwork();
      const currentChainId = "0x" + currentNet.chainId.toString(16).toLowerCase();
      if (currentChainId !== chainId.toLowerCase()) {
        try {
          await switchNetwork();
          ethersProvider = new BrowserProvider(walletProvider);
          signer = await ethersProvider.getSigner();
        } catch (switchErr) {
          console.error("Chain switch error:", switchErr);
          alert("Please switch your wallet to the correct network.");
          setLoading(false);
          return;
        }
      }

      const contract = new Contract(contractAddress, wshareAbi, signer);
      const tx = await contract.purchaseWSHARE(parseEther(wshareAmount), {
        value: parseEther(bnbAmount),
      });

      await tx.wait();
      setTransactionHash(tx.hash);
      alert(`WSHARE Purchase Successful! Transaction Hash: ${tx.hash}`);
      setWshareAmount("");
      setBnbAmount("");
      onClose();
    } catch (error) {
      setErrorMessage(error.message || "Transaction failed.");
      console.error("Transaction error:", error);
    } finally {
      setLoading(false);
    }
  };

  const totalCost = calculateTotalCost();

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Buy {token.name} ({token.symbol})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Spinner animation="border" variant="primary" />
            <span className="ms-2">Processing purchase...</span>
          </div>
        )}
        <div className="mb-3">
          <strong>Network:</strong> {token.chainName}
        </div>
        <hr />
        <div className="mb-3">
          <strong>Token Contract Address:</strong>
          <div className="d-flex justify-content-between align-items-center mt-1">
            <span className="text-monospace">
              {`${token.contractAddress.slice(0, 12)}...${token.contractAddress.slice(-12)}`}
            </span>
            <div>
              <Button variant="outline-secondary" size="sm" onClick={() => {
                navigator.clipboard.writeText(token.contractAddress);
                alert(`Copied to clipboard: ${token.contractAddress}`);
              }} className="me-2">
                <img src={copyIcon} alt="Copy" width="16" height="16" />
              </Button>
              <Button variant="outline-secondary" size="sm" onClick={addToMetaMask}>
                <img src={metamaskIcon} alt="MetaMask" width="16" height="16" />
              </Button>
            </div>
          </div>
        </div>
        <div className="mb-3 text-center">
          <p>
            You want: <strong>{wshareAmount}</strong> {token.symbol}
          </p>
          <p>
            Price per token: <strong>{token.stablePrice}</strong> {token.nativeSymbol}
          </p>
          <p>
            Total cost: <strong>{totalCost.toFixed(4)}</strong> {token.nativeSymbol}
          </p>
        </div>
        <Form.Group controlId="wshareAmount" className="mb-3">
          <Form.Label>Enter {token.symbol} Amount:</Form.Label>
          <Form.Control
            type="number"
            placeholder={`Amount of ${token.symbol}`}
            value={wshareAmount}
            onChange={handleWshareChange}
          />
        </Form.Group>
        <Form.Group controlId="bnbAmount" className="mb-3">
          <Form.Label>Required {token.nativeSymbol}:</Form.Label>
          <Form.Control
            type="text"
            value={bnbAmount}
            readOnly
            className="bg-light"
          />
        </Form.Group>
        {errorMessage && (
          <Alert variant="danger" className="mt-2">
            {errorMessage}
          </Alert>
        )}
        {transactionHash && (
          <Alert variant="success" className="mt-2">
            <strong>Transaction Hash:</strong>
            <br />
            {transactionHash}
          </Alert>
        )}
        <p className="text-muted small mt-2">
          Your purchased tokens will arrive once the transaction confirms.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={purchaseWshare}
          disabled={loading || !account?.address || !wshareAmount || parseFloat(wshareAmount) <= 0}
        >
          {loading ? "Processing..." : `Buy ${token.symbol}`}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WSHAREPurchaseModal;
